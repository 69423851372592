$(document).ready(function() {
  if ($('#paginate-infinite-scrolling').length > 0) {
    $(window).on('scroll', function() {
      more_medium_url = $('.page-item.next a').attr('href');
      if (more_medium_url && ($(window).scrollTop() > $(document).height() - $(window).height() - 60)) {
        $.getScript(more_medium_url);
      }
    });
  };
});
