import $ from 'jquery'

$(document).on('turbolinks:load ready', function() {
  $('#podio-verification').on('click', function(event) {
    event.preventDefault();
    const email_field = $('#referrer-email')
    $(this).attr('disabled', 'disabled');

    $.ajax({
      url: `${email_field.data('path')}`,
      type: 'POST',
      data: {
        email: email_field.val()
      },
      success: function(response) {
        successCallback(response);
      },
    });
  });
});

function successCallback(response) {
  let data = {}
  if(response.error) {
    $('#verification-message').html('Something went wrong. Please try again later!')
  }
  else if($.isEmptyObject(response.data)) {
    $('#verification-message').html('We din not found the referrer with given email address.')
  }
  else {
    data = response.data
  }

  populateReferreInfoFields(data)
  $('#podio-verification').removeAttr('disabled', 'disabled')
}

function populateReferreInfoFields(data) {
  $('#referrer-first-name').val(data['First Name'] || '');
  $('#referrer-last-name').val(data['Last Name'] || '');
  $('#referrer-phone').val(data['Stripped Phone'] || '');
}
